import React from 'react';
import { deepCopy } from '../../utils/deepCopy';
import constants from './constants';
import { Modal } from 'antd';
import FacetLoader from './children/FilterFacet/loader';
import FacetsMain from '../../components/FacetsMain';
import RenderComponent from '../../components/RenderComponent';
import SentimentDetails from './children/SentimentDetailModal';
export function getDataCount({data_count,loading}) {
    if(data_count===10000)
        data_count='10000+';
    return !!!loading&&
    <div className='result-count'>
        {data_count+' results found'}
    </div>;
}

export function resetFilters(){
    const newConfig = deepCopy(constants.defaultFilterConfig);
    this.setState({filterConfig:newConfig});
    const payload={
        text:newConfig.search,
        topic:newConfig.topics,
        sentiments:newConfig.sentiments,
        batch_id:newConfig.batchIds
    };
    this.fetchSentiments(payload,true);
}

export function getPaginationTotal(total,range){
    return `${range[0]}-${range[1]} of ${total} items`;
}

export function renderDetailsModal(){
    return(
        <Modal
            className='sentiment-modal'
            style={{top:20}}
            width='800px'
            footer={null}
            onCancel={()=>this.setState({modalVisibility:false})}
            visible={this.state.modalVisibility}>
            <div className='sentiment-body'>
                <SentimentDetails
                    api={this.props.sentimentApi}
                    activeText={this.state.activeText}/>
            </div>
        </Modal>
    );
}

export function renderFacet(){
    return (
        <RenderComponent
            api={this.props.filtersApi}
            loader={<FacetLoader/>} 
            children={()=>
                <FacetsMain
                    resetFacetList={this.onResetFacet}
                    onClickFacetItem={this.onClickFacetItem}
                    error={this.props.filtersApi.error}
                    updateActiveFacet={this.props.updateActiveFacet}
                    defaultFacetGroup={this.props.activeFacet}
                    facetObject = {this.props.filtersApi.data}/>}
        />
    );
}

export function getFetchDataPayload(data=[]){
    let response={};
    if(data)
        data.map(facetGroup=>{
            response[facetGroup.value]=[];
            facetGroup[facetGroup.value]
                .map(facet=>{
                    if(facet.selected)
                        response[facetGroup.value].push(facet.value);
                });
        });
    return response;
}