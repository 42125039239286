import ContentLoader from 'react-content-loader';
import React from 'react';

const FacetLoader = () => (
    <ContentLoader 
        height={300}
        width={250}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb" >
        <rect x="6" y="15" rx="3" ry="3" width="200" height="10" /> 
        <rect x="220" y="15" rx="3" ry="3" width="15" height="10" /> 
        <rect x="6" y="40" rx="3" ry="3" width="180" height="10" />
        <rect x="6" y="65" rx="3" ry="3" width="200" height="10" />
        <rect x="6" y="90" rx="3" ry="3" width="190" height="10" />
        <rect x="6" y="115" rx="3" ry="3" width="150" height="10" />
    </ContentLoader>
);
export default FacetLoader;