/*eslint-disable*/
import React from 'react';
export default {
    icons:{
        reload:'reload',
        shrink:'shrink',
        more:'more'
    },
    labels:{
        reset:'reset filters',
        toggleFilters:'toggle filter tags',
        left:'left'
    },
    defaultFilterConfig:{
        topics:[],
        search:'',
        sentiments:[],
        batchIds:[]
    },
    filtersFields:{
        topics:'topics',
        sentiments:'sentiments',
        batchIds:'batchIds',
        search:'search'
    },
    dorpDownPlaceholder: 'Batch Ids',
    tagsFields: [
        {
            title: 'Topics',
            dataIndex: 'topic',
            key: 'topic'
        },
        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score'
        }
    ],
    sentimentFields: [
        {
            key: 'subject',
            label: 'Subject'
        },
        {
            key: 'opinion',
            label: 'Opinion'
        },
        {
            key: 'sentiment_type',
            label: 'Sentiment Type'
        },
        {
            key: 'sentiment_value',
            label: 'Sentiment Value'
        }
    ],
    columns: [
        {
            title: 'Comments',
            dataIndex: 'text',
            key: 'text',
            render:row=><div dangerouslySetInnerHTML={{__html:row}}/>
        }
    ],
    sentimentEmptyMessage: 'No Sentiment Data Found !!',
    tagsEmptyMessage: 'No Data Found !!',
    emptySpace: '',
    active: 'active',
    contentTableStyle: {margin: '0px 15px'},
    tagsSubTableColumn: [
        {
            title: 'Matches',
            key: 'match',
            dataIndex: 'match'
        },
        {
            title: 'Scores',
            key: 'score',
            dataIndex: 'score'
        }
    ],
    defaultState: {
        expandedRows: [],
        activeText:999,
        selectedBatch: undefined,
        currentPage: 1,
        pageSize: 10
    },
    errorMessage: 'data fetching failed '
};