export default{
    defaultLoader:[
        {
            x:64,
            y:13,
            width:180
        },
        {
            x:968,
            y:13,
            width:83
        }
    ],
};