import React from 'react';
import { renderSentimentTable } from './renderSentimentTable';
import constants from '../constants';
import { Table, Empty, Spin, Card } from 'antd';
import { tagsSubTable } from './tagsSubTable';
import { addKey } from '../../../utils/addKey';
import RenderPieChart from './renderPieChart';

class SentimentDetails extends React.Component{

  state = {
      expandedRows:[],
  };
  
  componentWillUpdate(prevprops){
      if(prevprops.activeText!==this.props.activeText)
          this.setState({expandedRows:[]});
  }

  onExpandTopicsTable=(_,row)=>{
      let expandedRows=this.state.expandedRows;
      let index=expandedRows.indexOf(row.key);
      if(index>-1)
          expandedRows.splice(index,1);
      else
          expandedRows.push(row.key);
      this.setState({expandedRows});
  }

  renderContentTable(sentimentTable,fields){
      return(<Spin spinning={this.props.api.loading}>
          <div>
              <Table expandedRowKeys={this.state.expandedRows}
                  onExpand={this.onExpandTopicsTable}
                  className='tags-expandable-row'
                  expandedRowRender={tagsSubTable}
                  pagination={false}
                  style={constants.contentTableStyle}
                  size='small'
                  columns={constants.tagsFields}
                  dataSource={addKey(fields.topics)} />
              <Card 
                  className='piechart-card'
                  children={<RenderPieChart fields={fields} />}/>
              {sentimentTable}
          </div>
      </Spin>);
  }

  getContentTable() {
      let { data,loading } = this.props.api,fields={};
      if (((data&&data.data)||[]).length) {
          fields = data.data[this.props.activeText]||{};
          const sentimentTable =renderSentimentTable(fields
            &&fields.sentiments,constants.sentimentFields);
          return this.renderContentTable(sentimentTable,fields);
      }
      if(loading)
          return <Table loading={!!loading}/>; 
      return <Empty/>;
  }

  render(){
      return this.getContentTable();
  }
}
export default SentimentDetails;