import * as actionTypes from '../../actionTypes';
import axios from 'axios';
import { SENTIMENTAL_URL,
    BATCH_IDS_URL,
    FETCH_FILTERS_URL
} from '../../../config';
import constants from './constants';

const defaultSize=10;
const defaultFrom=0;
export const fetchSentiment = ({
    size=defaultSize,
    from=defaultFrom,
    batch_id=[],
    sentiments=[],
    text='',
    topic=[]
}) => {
    return dispatch => {
        dispatch({ type: actionTypes.FETCH_SENTIMENT_START });
        axios.post(SENTIMENTAL_URL, { 
            size,
            from,enableFilter:true,
            data:{batch_id,sentiments,text,topic} })
            .then(res => fetchSentimentSuccess(dispatch,{res,
                facets:{batch_id,sentiments,topic}}))
            .catch(err => fetchSentimentFailed(dispatch,err));
    };
};

function fetchSentimentSuccess(dispatch,{res,facets}){
    mapSentimentAndFilters({dispatch,res,facets});
    return dispatch({
        type:actionTypes.FETCH_SENTIMENT_SUCCESS,
        data:{
            data:res.data.data
            // filters:res.data.filters
        },
        count:res.data.total
    });
}

function mapSentimentAndFilters({dispatch,res,facets}){
    return dispatch({
        type:actionTypes.MAP_SENTIMENT_TO_FILTER,
        newFilters:res.data.filters,
        facets
    });
}

function fetchSentimentFailed(dispatch,err){
    return dispatch({
        type:actionTypes.FETCH_SENTIMENT_FAIL,
        error:err.message
    });
}

export const fetchBatchIds = () => {
    return dispatch => {
        dispatch({ type: actionTypes.FETCH_BATCH_IDS_STARTED });
        axios.get(BATCH_IDS_URL)
            .then(res => fetchBatchIdsSuccess(dispatch,res))
            .catch(err => fetchBatchIdsFailed(dispatch,err));
    };
};

function fetchBatchIdsSuccess(dispatch,res){
    return dispatch({
        type:actionTypes.FETCH_BATCH_IDS_SUCCESS,
        data:res.data.data
    });
}

function fetchBatchIdsFailed(dispatch,err){
    return dispatch({
        type:actionTypes.FETCH_BATCH_IDS_FAIL,
        error:err.message
    });
}

export const fetchFilters = () => {
    return dispatch => {
        dispatch({ type: actionTypes.FETCH_FILTERS_STARTED });
        axios.post(FETCH_FILTERS_URL,{})
            .then(res => fetchFiltersSuccess(dispatch,res))
            .catch(err => fetchFiltersFailed(dispatch,err));
    };
};

function fetchFiltersSuccess(dispatch,res){
    const filters={
        batch_id:res.data.filters.batch_id,
        topic:res.data.filters.topic,
        sentiments:res.data.filters.sentiments
    };
    const data=formatFilters({filters});
    return dispatchFetchFiltersSuccess(dispatch,data);
}

function dispatchFetchFiltersSuccess(dispatch,data){
    return dispatch({
        type:actionTypes.FETCH_FILTERS_SUCCESS,
        data
    });
}

function formatFilters(res,facets){
    const data=[],filters={
        batch_id:res.filters.batch_id,
        topic:res.filters.topic,
        sentiments:res.filters.sentiments};
    Object.keys(filters).map(key=>{
        data.push({
            main:key,value:key,label:getLabel(key),
            selected:!!facets&&facets[key].length,
            [key]:Object.keys(filters[key])
                .map(item=>({
                    value:item,
                    selected:!!facets&&facets[key]
                        .find(facet=>facet===item),
                    count:filters[key][item]}))
        });
    });
    return data;
}

function getLabel(key){
    return constants.facetMapper.find(item=>item.key===key).label;
}

function fetchFiltersFailed(dispatch,err){
    return dispatch({
        type:actionTypes.FETCH_FILTERS_FAIL,
        error:err.message
    });
}

export function updateActiveFacet(facet){
    return {
        type:actionTypes.UPDATE_ACTIVE_FACET,
        facet
    };
} 

export function updateFacetList({main,value}){
    return {
        type:actionTypes.UPDATE_FACET_LIST,
        main,
        value
    };
}

export function resetFacetList(){
    return {
        type:actionTypes.RESET_FACET_LIST
    };
}