import PieCharts from '../../Comments/children/pieChart';
import React from 'react';

function RenderPieChart({fields}){
    const res=[];
    for (let index = 0; index < fields.topics.length; index++) {
        const item = fields.topics[index];
        if(item.hasOwnProperty('score')
         && item.hasOwnProperty('topic'))
            res.push({
                topic:item.topic,
                score:item.score
            });   
    }
    if(!res.length) return '';
    return <PieCharts 
        cy={120}
        cx={250}
        radius={80}
        width={600}
        height={240}
        data={res}/>;
}
export default RenderPieChart;  