import React from 'react';
import { PieChart, Pie, Tooltip, Cell, Legend } from 'recharts';
import { getRandomColor } from '../../../utils/getRandomColor';
import constants from '../constants';
  
const PieCharts = ({
    data = [],height=250,width=600,cx,cy,radius }) => {
    const {properties,legend}=constants.pieChart;
    return <PieChart width={width} height={height}>
        <Pie animationDuration={properties.animationDuration}
            nameKey={properties.nameKey}
            dataKey={properties.dataKey}
            fill={properties.fill}
            data={data} 
            cx={cx||properties.cx} 
            cy={cy||properties.cy}
            outerRadius={radius||properties.outerRadius}
            children={data.map((customCell))} />
        <Legend 
            layout={legend.layout} 
            verticalAlign={legend.verticalAlign} 
            align={legend.align}  />
        <Tooltip />
    </PieChart>;
};

function customCell(_,index){
    return (
        <Cell 
            key={index}
            fill={getRandomColor(index)} />
    );
}

export default PieCharts;