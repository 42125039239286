export default {
    fields: ['highlight', 'topic', 'score'],
    columns: [
        {
            title: 'Comments',
            dataIndex: 'text',
            key: 'text',
        }
    ],
    emptyState:'No Data Found !!',
    emptySpace:'',
    active:'active',
    defaultState:{
        activeText:0,
        current:1,
        pageSize:10
    },
    pieChart:{
        properties:{
            dataKey:'score',
            nameKey:'topic',
            fill:'#8884d8',
            animationDuration:400,
            cx:200,
            cy:125,
            outerRadius:80,
            
        },
        legend:{
            layout:'vertical',
            verticalAlign:'middle',
            align:'left'
        }
    }
};