import { notification } from 'antd';
import {UPDATE_FIELDS} from '../../actionTypes';
import * as actionTypes from '../../actionTypes';
import Axios from 'axios';
import { UPLOAD_FILE,PROCESS_FILES_URL } from '../../../config';
import constants from './constants';

export function updateFields({event,index,target}) {
    return dispatch => {
        dispatch({
            type:UPDATE_FIELDS,
            event:event?event:target,
            index
        });
    };
}

export function fileUpload({event,index}){
    const {libraryFileApi,commentsFileApi}=constants;
    const field = index===0?libraryFileApi:commentsFileApi;

    let formData = new FormData();
    const file=event.target.files[0];
    formData.append(constants.file,file );

    return dispatch=>{
        dispatch({type:actionTypes.UPLOAD_FILE_START,field});
        Axios.post(UPLOAD_FILE,formData,constants.formDataHeader)
            .then(res=>fileUploadSuccess({dispatch,res,field,file}))
            .catch(err=>fileUploadFail({dispatch,err,field}));
    };
}

function fileUploadSuccess({dispatch,res,field,file}){
    notification.success({
        message: constants.fileUpoladSuccess
    });
    dispatch({
        type:actionTypes.UPLOAD_FILE_SUCCESS,
        data:res.data,
        field,
        fileName:file.name
    });
}

function fileUploadFail({dispatch,err,field}){
    notification.error({
        message: constants.fileUploadFail
    });
    dispatch({
        type:actionTypes.UPLOAD_FILE_FAIL,
        field
    });
}

export function processFiles(payload){
    return dispatch=>{
        dispatch({type:actionTypes.PROCESS_FILES_START});
        Axios.post(PROCESS_FILES_URL,payload,constants.formDataHeader)
            .then(res=>processFilesSuccess({dispatch,res}))
            .catch(err=>processFilesFail(dispatch,err));
    };
}

function processFilesSuccess({dispatch,res,index,file}){
    notification.success({message: constants.formProcessSuccess});
    dispatch({
        type:actionTypes.PROCESS_FILES_SUCCESS,
        data:res.data,
        index:index,
    });
}

function processFilesFail(dispatch,err){
    notification.error({message: constants.formProcessFail});
    dispatch({
        type:actionTypes.PROCESS_FILES_FAIL
    });
}

export function clearFormData(){
    return {
        type:actionTypes.CLEAR_FILES_DATA
    };
} 

export {
    fetchBatchIds
} from './getBatchIds';

export {
    deleteBatchId
} from './deleteBatchId';