import {
    apiFailureCase,
    apiSuccessCase, 
    apiStartState
} from '../../../utils/reduxOperations';

export function fetchSentimentStart(state) {
    return {
        ...state,
        sentimentApi: apiStartState(state.sentimentApi)
    };
};

export function fetchSentimentSuccess(state, action) {
    return {
        ...state,
        sentimentApi: apiSuccessCase(state.sentimentApi,action)
    };
}

export function fetchSentimentFail(state, action) {
    return {
        ...state,
        sentimentApi: apiFailureCase(state.sentimentApi,action)
    };
};

export function fetchBatchIdsStart(state) {
    return {
        ...state,
        batchIdsApi: apiStartState(state.batchIdsApi)
    };
};

export function fetchBatchIdsSuccess(state, action) {
    return {
        ...state,
        batchIdsApi: apiSuccessCase(state.batchIdsApi,action)
    };
}

export function fetchBatchIdsFail(state, action) {
    return {
        ...state,
        batchIdsApi: apiFailureCase(state.batchIdsApi,action)
    };
};

export function fetchFiltersStart(state) {
    return {
        ...state,
        filtersApi: apiStartState(state.filtersApi)
    };
};

export function fetchFiltersSuccess(state, action) {
    return {
        ...state,
        filtersApi: apiSuccessCase(state.filtersApi,action)
    };
}

export function fetchFiltersFail(state, action) {
    return {
        ...state,
        filtersApi: apiFailureCase(state.filtersApi,action)
    };
};

export function updateFacetList(state,{main,value}){
    const {data}=state.filtersApi;
    const facetGroup=data.find(filter=>filter.main===main);
    if(facetGroup){
        const facet=facetGroup[main].find(item=>item.value===value);
        if(facet){
            facet.selected=!facet.selected;
            if(facetGroup[main].find(facet=>facet.selected))
                facetGroup.selected=true;
            else facetGroup.selected=false;
        }
    }
    return {
        ...state,
        filtersApi:{
            ...state.filtersApi,
            data
        }
    };
}

export function resetFacetList(state){
    const {data}=state.filtersApi;
    data.map(facetGroup=>{
        facetGroup.selected=false;
        facetGroup[facetGroup.main].map(facet=>{
            facet.selected=false;
        });
    });
    return {
        ...state,
        filtersApi:{
            ...state.filtersApi,
            data
        }
    };
}

export function mapSentimentToFilter(state,action){
    const {data} = state.filtersApi;
    data.map(filter=>{
        filter[filter.value].map(item=>{
            if(action.newFilters[filter.value]){
                if(action.newFilters[filter.value][item.value])
                    item.count=action
                        .newFilters[filter.value][item.value];
                else item.count=null;
            }
        });
    });
    return {
        ...state,
        filtersApi:{
            ...state.filtersApi,
            data
        }
    };
}