import React from 'react';
import constants from './constants';
import { Icon } from 'antd';

export const LoaderButton = props => {
    return (
        <button
            test-name='loader-button'
            disabled={props.disabled || props.loader}
            onClick={props.onClick}
            className={props.classList.join(constants.emptySpace)}>
            {props.loader && 
            <Icon type='loading'/>
            }
            <span children={props.text} />
        </button>
    );
};