import React from 'react';
import {Table} from 'antd';
import constants from '../constants';

export function tagsSubTable(rec) {
    const data = Object.keys(rec.scores).map((scoreItem, key) => ({
        key,
        match: scoreItem,
        score: rec.scores[scoreItem]
    }));
    return (
        <Table
            pagination={false}
            size='small'
            columns={constants.tagsSubTableColumn}
            dataSource={data}
        />
    );
}
