import { apiSuccessCase } from '../../../../utils/reduxOperations';
import {setLocalStorage}
    from '../../../../utils/localStorageOperations';

export function handleLoginProcessSuccess(state, payload) {
    setLocalStorage(payload.res.data);
    return {
        ...state,
        loginApi:apiSuccessCase(state.loginApi,payload)
    };
}
