import React from 'react';
import ContentLoader from 'react-content-loader';
import constants from './constants';

const Loader = (props) =>{
    return <ContentLoader height={40} width={1060} speed={2}
        primaryColor="#d9d9d9" secondaryColor="#ecebeb">
        <rect x="30" y="13" rx="6" ry="6" width="20" height="5" />
        {constants.defaultLoader
            .map((item,key)=>getContent(item,key))}
        <rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" />
    </ContentLoader>;
};

const getContent=(value={},key)=>{
    const random = Math.random() * (1 - 0.7) + 0.7;
    return(
        <rect 
            key={key}
            x={value.x||5} 
            y={value.y||5}
            rx={value.rx||6}
            ry={value.ry||6}
            width={(value.width||5)*random}
            height={value.height||5}
        />);
};

const TableLoader = (props) => (
    Array(8)
        .fill(null)
        .map((_, index) => (
            <Loader 
                key={index}
                style={{ opacity: Number(2 / index).toFixed(1) }} />
        ))
);

export default TableLoader;