import React from 'react';
import './index.scss';
import { Layout, Menu, Icon } from 'antd';
import Header from '../../components/Header';
import { ROUTES } from '../../config';
import constants from './constants';
import { getDefaultRoute } from '../../utils/getDefaultRoute';
import {Switch} from 'react-router-dom';
import { getCurrentPath } from '../../utils/getCurrentPath';
import ProtectedRoute from '../../components/ProtectedRoute';

const { Sider, Content } = Layout;

class MainFrame extends React.Component {
    constructor(props) {
        super(props);
        const indexRoute=getCurrentPath();
        this.state = { collapsed: false };
        if (!ROUTES.find(route=>route.name===indexRoute))
            this.props.history.push(
                constants.parentPath.path+getDefaultRoute().path);
    }

  generateSideBar = () => {
      return (
          <Sider
              collapsible
              collapsed={this.state.collapsed}
              onCollapse={(value)=>this.setState({collapsed:value})} >
              <Menu
                  theme="light"
                  defaultSelectedKeys={[getCurrentPath()]}
                  mode="inline" >
                  {ROUTES.map(this.getMenuItems)}
              </Menu>
          </Sider>
      );
  }

  onClickRoute=(route)=>{
      const path=constants.parentPath.path;
      this.props.history.push(path+route);
  }

  getMenuItems=(route)=>{
      const path=constants.parentPath.path;
      return(
          <Menu.Item
              key={route.name}
              onClick={()=>this.props.history.push(path+route.path)}>
              <span>
                  <Icon type={route.icon}/>
                  <span>{route.label}</span>
              </span>
          </Menu.Item>
      );
  }

  generateContent(){
      return <Switch>
          {ROUTES.map(route =>{
              return (
                  <ProtectedRoute
                      path={route.path} 
                      key={route.path}
                      name={route.name}
                      component={route.component}/>
              );
          })
          }
      </Switch>;
  }

  render() {
      return (
          <div className='main-wrapper'>
              <Layout>
                  <Header onClickRoute ={this.onClickRoute}/>
                  <Layout 
                      style={constants.layoutStyle}>
                      {/* {this.generateSideBar()} */}
                      <Content 
                          style={constants.contentStyle}
                          children={this.generateContent()} />
                  </Layout>
              </Layout>
          </div>
      );
  }
}

export default MainFrame;