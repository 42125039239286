import * as actionTypes from '../../actionTypes';
import Axios from 'axios';
import { LOGIN_URL } from '../../../config';

export const loginAction = params => {
    return dispatch => {
        dispatch({type:actionTypes.LOGIN_PROCESS_STARTED});
        return Axios.post(LOGIN_URL, params)
            .then(res => {
                dispatch({
                    type:actionTypes.LOGIN_PROCESS_SUCCESS,
                    res:res.data
                });    
            })
            .catch(err => {
                dispatch({
                    type:actionTypes.LOGIN_PROCESS_FAILED,
                    error:err.response.data.message
                });
            });
    };
};
