import { connect } from 'react-redux';
import { 
    loginFormChange,
    loginAction
} from '../../Redux/Actions/LoginPageAction';
import Login from './Login';
import './index.scss';
import { getUserProfile } 
    from '../../Redux/Actions/getUserProfileAction';

const mapStateToProps = state => ({
    ...state.LoginReducer,
    ...state.getProfilePageReducer
});

const mapDispatchToProps = dispatch => ({
    loginFormChange: event => dispatch(loginFormChange(event)),
    loginAction:payload=>dispatch(loginAction(payload)),
    getUserProfile:()=>dispatch(getUserProfile())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
