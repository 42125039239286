import React from 'react';
import chart from './chart';
import './index.scss';
import * as d3 from 'd3';


class Bubble extends React.Component{
    
    componentDidUpdate(){
        d3.select('#bubble-chart svg').remove();
        chart(this.props);
    }
    
    componentDidMount(){
        d3.select('#bubble-chart svg').remove();
        chart(this.props);
    }

    render(){
        return (
            <div
                className='bubble'
                id={'bubble-chart'}/>
        );
    }
}
export default Bubble;