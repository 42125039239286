import React from 'react';
import { Redirect } from 'react-router-dom';
import { getAccessToken } from './localStorageOperations';


const redirectTo = (payload) => {
    return (
        <Redirect
            to={{
                pathname: '/login',
                state: { from: payload.location }
            }} />
    );
};

export const RenderIfSignIn = (properties,Component) => {
    if (getAccessToken('accessToken'))
        return <Component {...properties}/>;
    else 
        return redirectTo(properties);
};