import {
    apiFailureCase,
    apiSuccessCase,
    apiStartState,
    apiInitialState
} from '../../../utils/reduxOperations';
import { fetchFormConfigurations
} from '../../inputFormConfigurations';

export function uploadFileStart(state,action) {
    return {
        ...state,
        [action.field]: apiStartState(state[action.field])
    };
};

export function uploadFileSuccess(state, action) {
    const api=apiSuccessCase(state[action.field], action);
    return {
        ...state,
        [action.field]: {
            ...api,
            name:action.fileName
        }
    };
}

export function uploadFileFail(state, action) {
    return {
        ...state,
        [action.field]: apiFailureCase(state[action.field], action)
    };
};


export function processFilesStart(state) {
    return {
        ...state,
        processFilesApi: apiStartState(state.processFilesApi)
    };
};

export function processFilesSuccess(state, action) {
    return {
        ...state,
        ...clearData(state),
        processFilesApi:apiSuccessCase(state.processFilesApi, action)
    };
}

export function processFilesFail(state, action) {
    return {
        ...state,
        ...clearData(state),
        processFilesApi: apiFailureCase(
            state.processFilesApi, action),
    };
};


export function updateFields(state, action) {
    const inputBoxConfiguration =
        Object.assign([], state.inputBoxConfiguration);
    if (action.event.target) {
        inputBoxConfiguration[action.index] = {
            ...inputBoxConfiguration[action.index],
            value: action.event.target.value,
            isValid: action.event.target.value ? true : false
        };
    }
    return {
        ...state,
        inputBoxConfiguration
    };
}

export function clearData(state){
    return{
        ...state,
        commentsFileApi:apiInitialState(),
        libraryFileApi:apiInitialState(),
        inputBoxConfiguration:fetchFormConfigurations(['batchId'])
    };

}

export function downloadLibraryStart(state){
    return{
        ...state,
        downloadDefaultTopicApi:{
            loading:true
        }
    };
}

export function downloadLibrarySuccess(state){
    return{
        ...state,
        downloadDefaultTopicApi:{
            loading:false
        }
    };
}

export function downloadLibraryFail(state){
    return{
        ...state,
        downloadDefaultTopicApi:{
            loading:false
        }
    };
}

export function getBatchIdsStart(state) {
    return {
        ...state,
        getBatchIdsApi: apiStartState(state.getBatchIdsApi)
    };
};

export function getBatchIdsSuccess(state, action) {
    return {
        ...state,
        getBatchIdsApi: apiSuccessCase(state.getBatchIdsApi,action)
    };
}

export function getBatchIdsFail(state, action) {
    return {
        ...state,
        getBatchIdsApi: apiFailureCase(state.getBatchIdsApi,action)
    };
};