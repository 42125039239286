export function setLocalStorage(payload){
    for (let item in payload)
        localStorage.setItem(item, setItemValue(payload[item]));
}
function setItemValue(value) {
    if (typeof value != 'string') return JSON.stringify(value);
    else return value;
}

export function getAccessToken(){
    return localStorage.getItem('accessToken');
}

export function ClearLocalStorage(){
    localStorage.clear();
}
export const GetLocalStorage = key => {
    return getItemValue(localStorage.getItem(key));
};

function getItemValue(item) {
    try {
        return JSON.parse(item);
    } catch (err) {
        return item;
    }
}

export const CheckInLocalStorage = key => {
    return localStorage.getItem(key) ? true : false;
};