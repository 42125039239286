import { getRandomColor } from './getRandomColor';

export function formatData({nodes=[],edges=[]}){
    edges=edges.map(item=>({
        ...item,
        source:item.startNode,
        target:item.endNode
    })
    );
    nodes=nodes.map((item,index)=>({
        ...item,
        color:getRandomColor(index)
    }));
    return {nodes,edges};
}