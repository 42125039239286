import { apiInitialState } from '../../../utils/reduxOperations';
import { fetchFormConfigurations } 
    from '../../inputFormConfigurations';

export default {
    processFilesApi: apiInitialState(),
    inputBoxConfiguration: fetchFormConfigurations(['batchId']),
    libraryFileApi:apiInitialState(),
    commentsFileApi:apiInitialState(),
    downloadDefaultTopicApi:{loading:false},
    getBatchIdsApi:apiInitialState()
};