import React from 'react';
import constants from '../../constants';
import { Button,Icon } from 'antd';
import './index.scss';

export function FileUpload({ title, index, api, onUpload }){
    const { field,loading,upload } = constants;
    return <div className='input__file'>
        <input
            className='input__hidden'
            type={field.type}
            name={field.name}
            onChange={(e) => onUpload(index, e)} />
        <Button
            className='input__shown'>
            <Icon type={api.loading?loading:upload}/>
            {title}
            <span className='input__file__details'
                children={api.loading?null:api.name} />
        </Button>
    </div>;
}