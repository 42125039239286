import * as actionTypes from '../actionTypes';
import axios from 'axios';
import { TOPICS_URL, KEYWORD_URL, TOPIC_RELATION_URL }
    from '../../config';

const defaultPage = 0;
const defaultSize = 10;

export const fetchTopicsRelation = ({size=7,batchId}) => {
    return dispatch => {
        dispatch({type:actionTypes.FETCH_TOPICS_RELATION_START});
        axios.post(TOPIC_RELATION_URL, {
            size,
            data:{batch_id:batchId}
        })
            .then(res => fetchTopicsRelationSuccess({dispatch,res}))
            .catch(err => fetchTopicsRelationFailed(dispatch, err));
    };
};

function fetchTopicsRelationSuccess({ dispatch, res}) {
    return dispatch({
        type: actionTypes.FETCH_TOPICS_RELATION_SUCCESS,
        data: res.data
    });
};

function fetchTopicsRelationFailed(dispatch, err) {
    return dispatch({
        type: actionTypes.FETCH_TOPICS_RELATION_FAIL,
        error: err.message
    });
}

export const fetchTopics = ({
    keyword,
    page = defaultPage,
    size = defaultSize
}) => {
    return dispatch => {
        dispatch({ type: actionTypes.FETCH_TOPICS_START });
        axios.post(TOPICS_URL, { 'current-page': page, size,keyword})
            .then(res => fetchTopicsSuccess({ dispatch, res,keyword}))
            .catch(err => fetchTopicsFailed(dispatch, err));
    };
};

function fetchTopicsSuccess({ dispatch, res, keyword }) {
    return dispatch({
        type: actionTypes.FETCH_TOPICS_SUCCESS,
        data: res.data['topic-data'],
        count: res.data['total-topics'],
        keyword
    });
};

function fetchTopicsFailed(dispatch, err) {
    return dispatch({
        type: actionTypes.FETCH_TOPICS_FAIL,
        error: err.message
    });
}

export const fetchKeywords = () => {
    return dispatch => {
        dispatch({ type: actionTypes.FETCH_KEYWORD_START });
        axios.get(KEYWORD_URL)
            .then(res => {
                dispatch({
                    type: actionTypes.FETCH_KEYWORD_SUCCESS,
                    data: res.data
                });
            })
            .catch(err => {
                dispatch({
                    type: actionTypes.FETCH_KEYWORD_FAIL,
                    error: err.message
                });
            });
    };
};