import React, { Component } from 'react';
import { Table, Spin } from 'antd';
import constants from '../constants';
import './index.scss';
import { isActive } from '../../../utils/isActive';

class KeywordsTable extends Component {
    constructor(props) {
        super(props);
        this.props.fetchKeywords();
    }

  onItemClick = (record) => 
      this.props.fetch_Topics({keyword:record.key});

  render() {
      const { data,loading } = this.props.keywordApi;
      return (
          <Spin spinning={loading}>
              <Table
                  className='keyword__table'
                  rowClassName={(rec) => 
                      isActive(rec.key,this.props.keyword)}
                  onRow={(rec) => 
                      ({ onClick: () => this.onItemClick(rec) })}
                  columns={constants.keywordColumns}
                  dataSource={data || []}
                  pagination={false} />
          </Spin>
      );
  }
}

export default KeywordsTable;