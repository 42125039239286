import React from 'react';
import { formatData } from '../../../utils/topicRelationUtils';
import Bubble from '../../../components/Bubble';
import { InputNumber, Alert } from 'antd';
import { Card } from 'react-bootstrap';
import constants from '../constants';
import Loader from './topicGraphLoader';
import { DropDown } from '../../../components/DropDown';

export class RenderTopicsRelation extends React.Component{

    state={count:constants.defaultBubbleCount};

    onChangeCount=(count)=>{
        if(count!==this.state.count){
            this.props.onChange(count);
            this.setState({count});
        }
    }

    CardHeader() {
        return (
            <Card.Header>
                <div className='topics__card_header'>
                    <h2 children={constants.relationsTitle} />
                    <DropDown 
                        single
                        onChange={this.props.onChangeDropdown}
                        placeholder={constants.dropdownPlaceholder}
                        data={this.props.dropdownData}/>
                    <InputNumber
                        onChange={this.onChangeCount}
                        defaultValue={this.state.count} />
                </div>
            </Card.Header>
        );
    }

    render(){
        const { data, loading, error } = this.props.apiData;
        let graph = constants.emptySpace;
        if (loading) graph = <Loader />;
        if (data && data.edges && data.nodes) 
            graph = <Bubble data={formatData(data)} />;
        if(data && (!data.edges.length && !data.nodes.length))
            graph= <Alert 
                type={constants.warning}
                message={constants.emptyMessage} />;
        if (error)
            graph = <Alert 
                type={constants.error}
                message={constants.topicFinderErrorMsg} />;
        return (
            <Card className='topic-relation-graph'>
                {this.CardHeader()}
                <Card.Body children={graph} />
            </Card> );
    };
}