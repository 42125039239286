
import React from 'react';
import cbLogo from '../../Assets/images/logo_color.png';
import constants from './constants';
import { Dropdown } from 'react-bootstrap';
import userIcon from '../../Assets/images/user.png';
import {
    ClearLocalStorage, GetLocalStorage 
} from '../../utils/localStorageOperations';
import { Menu, Icon } from 'antd';
import { ROUTES } from '../../config';
import { getCurrentPath } from '../../utils/getCurrentPath';

const { SubMenu } = Menu;
class Header extends React.Component {
    state = {
        current: 'mail',
    };
    
    userName() {
        const {local}=constants;
        let firstName = GetLocalStorage(local.firstName);
        let lastName = GetLocalStorage(local.lastName);
        return (
            <span>
                {firstName+constants.emptySpace+lastName}
            </span>
        );
    }

    handleLogout() {
        window.location.href = constants.logout_url;
        ClearLocalStorage();
    }
    
    logOutItem() {
        return (
            <Dropdown.Item onClick={this.handleLogout}>
                <i className='fas fa-sign-out-alt' />
                <span>{constants.logout}</span>
            </Dropdown.Item>
        );
    }

    dropDown=() =>
        <li>
            <Dropdown>
                <Dropdown.Toggle
                    className='profile-img'
                    variant={constants.dropDown.variant}
                    id={constants.dropDown.id}
                    children={
                        <img src={userIcon} 
                            alt={constants.space}/>
                    }/>
                <Dropdown.Menu>
                    <Dropdown.Item>
                        <i className='fas fa-user' />
                        {this.userName()}
                    </Dropdown.Item>
                    {this.logOutItem()}
                </Dropdown.Menu>
            </Dropdown>
        </li>

    renderMenu=()=>{
        return (
            <Menu 
                style={{marginLeft:'200px'}}
                selectedKeys={[getCurrentPath()]}
                mode="horizontal">
                {ROUTES.map(route=>
                    <Menu.Item  key={route.name}
                        onClick={this.props.onClickRoute
                            .bind(null,route.path)} >
                        <Icon type={route.icon} />
                        <span>{route.label}</span>
                    </Menu.Item>
                )} 
            </Menu>);
    }
    
    render(){
        return (
            <div className='main-header'>
                <div className='main-header__brand'>
                    <a href='/'>
                        <span>
                            <img 
                                src={cbLogo} 
                                alt={constants.logo} />
                        </span>
                    </a>
                </div>
                {this.renderMenu()}
                <div className='main-header__menu'>
                    <ul children={this.dropDown()}
                        className='main-header__menu--list'/>
                </div>
            </div>
        );
    }
};

export default Header;