import React from 'react';
import './index.scss';
import { Card, Table, Spin, Alert} from 'antd';
import constants from './constants';
import { isActive } from '../../utils/isActive';
import { addKey } from '../../utils/addKey';
import RenderFiltersAndSearch from'./children/renderFiltersAndSearch';
import * as helpers from './helper';
class Sentiment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisibility:false, 
            search:'',
            activeText: constants.defaultState.activeText,
            current:constants.defaultState.currentPage,
            pageSize:constants.defaultState.pageSize,
            typingTimeout:0,
        };
        this.fetchSentiments({});
        this.props.fetchFilters();
    }

    onSelectText = (index) =>
        this.setState({ 
            activeText:index,
            modalVisibility:true,
        });

    onChangepagination = ({ pageSize, current }) => {
        this.setState({
            current,
            pageSize,
            activeText:constants.defaultState.activeText
        });
        this.fetchSentiments({
            size: pageSize,
            from: (current * pageSize) - pageSize
        });
    }
    onChangeSearch=(event)=>{
        this.setState({search:event.target.value});
        this.resetState();
        this.setTimeoutForFetchData();
    }

    setTimeoutForFetchData = () => {
	    if (this.state.typingTimeout)
	        clearTimeout(this.state.typingTimeout);
	    this.setState({
	        typingTimeout: setTimeout(()=> {
	            this.fetchSentiments({});
	        }, 1000)
	 });
    };
    
    onCloseTag=(field,item)=>{
        const {filterConfig} = this.state;
        filterConfig[field]=filterConfig[field]
            .filter(filterItem=>filterItem!==item);
        this.setState({filterConfig},this.fetchSentiments({}));
    }

  getMainTablePagination=()=>({
      showTotal:helpers.getPaginationTotal,
      showSizeChanger: true,
      total: this.props.sentimentApi.data_count,
      current:this.state.current,
      pageSize:this.state.pageSize
  });

  fetchSentiments=(payload,reset)=>{
      if(reset) return this.props.fetchSentiment(payload);
      const data=helpers
          .getFetchDataPayload(this.props.filtersApi.data);
      let {
          size,
          from,
          search=this.state.search,
          batch_id=data.batch_id,
          sentiments=data.sentiments,
          topic=data.topic
      }=payload;
      this.props.fetchSentiment({size,from,
          batch_id,topic,text:search,sentiments}); 
  }

  renderFilterAndSearch=()=>(
      <RenderFiltersAndSearch 
          resetFilter={helpers.resetFilters.bind(this)}
          onCloseTag={this.onCloseTag}
          search={this.state.search}
          onChange={this.onChangeSearch}
          data={this.props.filtersApi.data} />
  )
  getMainTable() {
      let { sentimentApi } = this.props;
      return (
          <div className='sentiment-body__table'>
              {this.renderFilterAndSearch()}
              <Spin spinning={this.props.sentimentApi.loading}>
                  {helpers.getDataCount(sentimentApi)}
                  <Table onChange={this.onChangepagination}
                      pagination={this.getMainTablePagination()}
                      rowClassName={(row, index) => 
                          isActive(index,this.state.activeText)}
                      onRow={(row, index) => 
                          ({onClick:()=>this.onSelectText(index)})}
                      columns={constants.columns} 
                      dataSource={addKey(sentimentApi
                          .data&&sentimentApi.data.data)} />
              </Spin>
          </div>
      );
  }

  renderContent=()=>{
      const {error}=this.props.sentimentApi;
      let content;
      if(error) content = <Alert 
          type='error' message={constants.errorMessage} />;
      else content=this.getMainTable();
      return(
          <Card 
              children={content}
              className='sentiment-body'/>
      );
  }

  resetState=()=>
      this.setState({
          current:constants.defaultState.currentPage,
          pageSize:constants.defaultState.pageSize,
          activeText:constants.defaultState.activeText,
      });

  onResetFacet=()=>{
      this.props.resetFacetList();
      this.fetchSentiments({});
      this.resetState();
  }

  onClickFacetItem=(payload)=>{
      this.props.updateFacetList(payload);
      this.setTimeoutForFetchData();
      this.resetState();
  }

  render() {
      return (
          <div className='sentiment'>
              <div className='sentiment-facet'>
                  {helpers.renderFacet.bind(this)()}
              </div>
              {this.renderContent()}
              {helpers.renderDetailsModal.bind(this)()}
          </div>
      );
  }
}
export default Sentiment;