import React from 'react';
import './index.scss';
import { Card } from 'react-bootstrap';
import { Icon, Tooltip } from 'antd';
import constants from './constants';
import { LoaderButton } from '../../components/LoaderButton';
import FormField from '../../components/FormField';
import { FileUpload } from './children/FileUpload';
import RenderBatchIds from './children/RenderBatchIds/';

class Admin extends React.Component {

    componentWillUnmount() {
        this.props.clearFormData();
    }

    onInputChange = (payload) => {
        if (this.props.commentsFileApi.name &&
            this.props.libraryFileApi.name)
            this.props.updateFields(payload);
    }

    onUploadFile = (index, event) => {
        if (event.target.files.length)
            this.props.fileUpload({ event, index });
    }

    getDownloadButton=()=>{
        const {downloadDefaultTopicApi} = this.props;
        return(
            <Tooltip title={constants.downloadButtonText}>
                <Icon onClick={this.props.downloadDefaultTopics}
                    type={downloadDefaultTopicApi.loading
                        ?constants.loading:constants.download}/>
            </Tooltip>
        );
    }

    getUploadButton=()=>{
        const {libraryFileApi} = this.props;
        return(
            <div className='input-form__body--download'>
                <FileUpload 
                    index={0}
                    onUpload={this.onUploadFile}
                    api={libraryFileApi}
                    title={constants.libraryUploadText} />
            </div>
        );
    }

    addSurveyBody = () => {
        const { commentsFileApi} = this.props;
        return <React.Fragment>
            <div className='input-form__body'>
                {this.getUploadButton()}
                <FileUpload index={1}
                    onUpload={this.onUploadFile}
                    api={commentsFileApi}
                    title={constants.commentsUploadText} />
                <FormField
                    index={0}
                    loginFormChange={this.onInputChange}
                    onEnter={this.onSubmitForm}
                    field={this.props.inputBoxConfiguration[0]} />
            </div>
        </React.Fragment>;
    }

    onSubmitForm = () => {
        const { inputBoxConfiguration, commentsFileApi, libraryFileApi
        } = this.props;
        const requestBody = {};
        requestBody.batch_id = inputBoxConfiguration[0].value;
        requestBody.comments_file = commentsFileApi.data.fileName;
        requestBody.topics_library_file=libraryFileApi.data.fileName;
        this.props.processFiles(requestBody);
    }

    addSurveyFooter() {
        const { processFilesApi } = this.props;
        return (
            <LoaderButton
                loader={processFilesApi.loading}
                onClick={this.onSubmitForm}
                disabled={this.checkButtonDisabled()}
                classList={constants.loaderButton.classes}
                loaderClass={constants.loaderButton.loaderClass}
                text={constants.buttonText} />
        );
    }

    checkButtonDisabled = () => {
        let value = true;
        this.props.inputBoxConfiguration.map(item => {
            if (!item.isValid)
                value = false;
        });
        return !value;
    }

    addSurveyHeader=()=>{
        return(
            <div className='input-form__header' >
                <h3>{constants.header}</h3>
                {this.getDownloadButton()}
            </div>
        );
    }

    renderContent=()=>{
        return(
            <Card className='input-form'>
                <Card.Header children={this.addSurveyHeader()}/>
                <Card.Body
                    children={this.addSurveyBody()} />
                <Card.Footer
                    children={this.addSurveyFooter()} />
            </Card>
        );
    }


    render() {
        return (
            <div className='add-block'>
                <RenderBatchIds
                    batchIdsApi={this.props.getBatchIdsApi}
                    fetchData={this.props.fetchBatchIds}
                    deleteBatchId={this.props.deleteBatchId}
                />
                {this.renderContent()}
            </div>
        );
    }
}

export default Admin;