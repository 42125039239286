import {FORM_VALIDATION_CONSTANTS} from './regexConfigurations';
const formConfigurations = [
    {
        name: 'firstName',
        type: 'text',
        placeholder: 'First Name',
        isValid: true,
        rule: {
            condition: getRuleCondition('name'),
            errorMessage: 'Please enter a valid name',
            emptyMessage: 'First-Name cannot be emplty!'
        },
        message: '',
        value: ''
    },
    {
        name: 'lastName',
        type: 'text',
        placeholder: 'LastName',
        isValid: true,
        rule: {
            condition: getRuleCondition('name'),
            errorMessage: 'Please enter a valid name',
            emptyMessage: 'Last-Name cannot be emplty!'
        },
        message: '',
        value: ''
    },
    {
        name: 'username',
        type: 'email',
        placeholder: 'username',
        isValid: true,
        message: '',
        rule: {
            condition: getRuleCondition('email'),
            errorMessage: 'Invalid Username',
            emptyMessage: 'Username cannot be empty'
        },
        value: ''
    },
    {
        name: 'password',
        type: 'password',
        placeholder: 'Password',
        isValid: true,
        message: '',
        rule: {
            condition: getRuleCondition('password'),
            errorMessage: 'Invalid Password',
            emptyMessage: 'Password cannot be empty'
        },
        value: ''
    },
    {
        name: 'organisationName',
        type: 'text',
        placeholder: 'Organization Name',
        isValid: true,
        rule: {
            condition: getRuleCondition('organisationName'),
            errorMessage: 'Invalid organisation name',
            emptyMessage: 'organisation name cannot be empty'
        },
        message: '',
        value: ''
    },
    {
        name: 'verificationCode',
        type: 'text',
        placeholder: 'Enter code',
        isValid: true,
        rule: {
            condition: getRuleCondition('verificationCode'),
            errorMessage: 'Please enter valid code',
            emptyMessage: "verification field can't be emplty!"
        },
        message: '',
        value: ''
    },
    {
        name: 'newPassword',
        type: 'password',
        placeholder: 'Password',
        rule: {
            condition: getRuleCondition('password'),
            errorMessage: 'Please enter a valid password',
            emptyMessage: 'Password cannot be emplty!'
        },
        isValid: true,
        message: '',
        value: ''
    },
    {
        name: 'confirm',
        type: 'password',
        placeholder: 'Confirm New Password',
        rule: {
            condition: getRuleCondition('password'),
            errorMessage: 'Confirm password does not matches',
            emptyMessage: 'Confirm Password cannot be empty!'
        },
        isValid: true,
        message: '',
        value: ''
    },
    {
        name: 'email',
        type: 'email',
        placeholder: 'Email',
        isValid: true,
        rule: {
            condition: getRuleCondition('email'),
            errorMessage: 'Invalid Email',
            emptyMessage: "Email field can't be empty!"
        },
        message: '',
        value: ''
    },
    {
        name: 'workEmail',
        type: 'email',
        placeholder: 'Work Email',
        isValid: true,
        rule: {
            condition: getRuleCondition('email'),
            errorMessage: 'Invalid Email',
            emptyMessage: "Email field can't be empty!"
        },
        message: '',
        value: ''
    },
    {
        name: 'phone',
        type: 'number',
        placeholder: 'Phone Number',
        isValid: true,
        rule: {
            condition: getRuleCondition('phoneNumber'),
            errorMessage: 'Invalid Phone Number',
            emptyMessage: " Phone number field can't be empty!"
        },
        message: '',
        value: ''
    },
    {
        name: 'surveyFile',
        type: 'file',
        isValid: false,
        message: '',
        file_name: '',
        file_type: 'doc/csv',
        file: {}
    },
    {
        name: 'batchId',
        type: 'text',
        isValid: false,
        message: '',
        value:'',
        placeholder:'Batch Id'
    }
];

function getRuleCondition(type) {
    return FORM_VALIDATION_CONSTANTS[type];
}

export function fetchFormConfigurations(nameArray) {
    return formConfigurations.filter(configuration =>
        nameArray.includes(configuration.name)
    );
}
