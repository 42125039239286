export default {
    header:'Process Comments',
    uploadBtntext:'Your file upload Here',
    buttonText:'Process',
    loaderButton:{
        classes:['btn', 'btn-primary', 'loder-btn'],
        loaderClass:['xs white']
    },
    field:{
        type:'file',
        name:'survey',
        fileType:'.csv'
    },
    libraryUploadText:'Upload Topics Library File',
    commentsUploadText:'Upload Comments File',
    emptyString:'',
    hidden:'hidden',
    loading:'loading',
    upload:'upload',
    download:'download',
    downloadButtonText:'Download Default Topics Library',
    batchIdList:{
        title:'Batch Ids',
        emptyMessage:'no data found',
        errorMessage:'data fetching failed',
        size:'small',
        popup:{
            title:'Are you sure delete this batch id?',
            okText:'Yes',
            cancelText:'No',
            iconType:'delete'
        }
    }
};