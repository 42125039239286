import { DELETE_BATCH_IDS_URL } from '../../../config';
import Axios from 'axios';
import { notification } from 'antd';
import { fetchBatchIds } from './getBatchIds';

export const deleteBatchId = (id) => {
    return dispatch => {
        Axios.get(DELETE_BATCH_IDS_URL+id)
            .then(res => deleteBatchIdSuccess(dispatch,res))
            .catch(err => deleteBatchIdFailed(dispatch,err));
    };
};

function deleteBatchIdSuccess(dispatch,res){
    notification.success({message:'batch id deleted successfully'});
    return dispatch(fetchBatchIds());
}

function deleteBatchIdFailed(dispatch,err){
    notification.error({message:'deleting batch id failed'});
    return dispatch({
        type:'',
    });
}