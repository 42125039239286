export function findRangeFromNodes(nodes = []) {
    const counts = nodes.map(node => node.count);
    let max = Math.max(...counts);
    let min = Math.min(...counts);
    if (min === max)
        max = min + 3;
    return { min, max };
}

export function findRangeFromEdge(edges = []) {
    const counts = edges.map(edge => edge.width);
    return {
        max: Math.max(...counts),
        min: Math.min(...counts),
    };
}


export function customTooltip(rec) {
    const sentiments = rec.sentiments || {};
    return (
        `<div class='tooltip-wrapper'>

        <div class="tooltip-title">
          <p>${rec.node} (${rec.count})</p>
        </div>
    
        <div class="tooltip-body">
          <table class="tooltip-body--table">
            ${Object.keys(sentiments).map(
            (key) => getTooltipValue(key, sentiments)).join(' ')}
          </table>
        </div>
      </div>`
    );
}

function getTooltipValue(key, sentiments) {
    return `<tr>
  <td>${key} </td>
  <td>${ sentiments[key]}</td>
</tr>`;
}