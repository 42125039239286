import { setLocalStorage } 
    from '../../../../utils/localStorageOperations';
import { apiSuccessCase } from '../../../../utils/reduxOperations';

export function getUserProfileSuccess(state, payload) {
    setLocalStorage(payload.data.data.Item);
    
    return {
        ...state,
        getUserProfileApi:
        apiSuccessCase(state.getUserProfileApi,payload.data.data)
    };
}
