import { combineReducers } from 'redux';
import CommentsReducer from './Reducers/CommentsReducer';
import SentimentReducer from './Reducers/SentimentReducer';
import TopicsReducer from './Reducers/TopicsReducer';
import LoginPageReducer from './Reducers/LoginPageReducer';
import AdminReducer from './Reducers/AdminReducer';
import getProfilePageReducer from './Reducers/GetUserProfileReducer';

const rootReducer = combineReducers({
    CommentsReducer,
    TopicsReducer,
    SentimentReducer,
    getProfilePageReducer,
    LoginReducer:LoginPageReducer,
    AdminReducer
});
export default rootReducer;