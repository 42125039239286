export default{
    login:'login',
    logo:'logo',
    btn:{
        classess:['btn btn-primary loder-btn'],
        loaderClass:['xs white'],
        text:'Sign in'
    },
    path:{
        dashboard:'/',
        home:'/',
        forgot:'/forgot',
        verify:'/verify',
        verifyRedirectTo:'verify?username='
    },
    forgotPassword:'Forgot Password',
    rememberMe:'Remember Me',
    loaderClasses:['lg', 'blue'],
    footerText:'© 2019 Culturebie LLC. All Rights Reserved.',
    newPasswordRequired:'NEW_PASSWORD_REQUIRED',
    local:{
        idToken:'idToken',
        userRole:'user_role'
    }
};