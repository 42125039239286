import Topics from './Routes/Topics';
// import Comments from './Routes/Comments';
import Sentiment from './Routes/Sentiment';
import Admin from './Routes/Admin/';

const loginBaseUrl=`https://ohq8qauxv8.execute-api.
us-east-1.amazonaws.com/dev/api/`;
export const LOGIN_URL=loginBaseUrl+'users/nativelogin';
export const GET_USER_PROFILE_URL=loginBaseUrl+'users/getuserprofile';

const baseUrlLocal='http://192.168.1.139:9090';
const baseUrl='https://api.nlp.culturebie.com/api';
export const TOPICS_URL = baseUrl+'/topics';
export const KEYWORD_URL = baseUrl+'/keywords';
export const COMMENTS_URL = baseUrl+'/comments';
export const SENTIMENTAL_URL = baseUrl+'/search';
export const TOPIC_RELATION_URL = baseUrl+'/topics/relation';
export const UPLOAD_FILE=baseUrl+'/upload';
export const BATCH_IDS_URL=baseUrl+'/batch_ids';
export const FETCH_FILTERS_URL=baseUrl+'/filter';
export const DELETE_BATCH_IDS_URL=baseUrl+'/batch_ids/delete/';
export const PROCESS_FILES_URL=baseUrl+'/comments/process';
export const DEFAULT_LIBRARY_URL=baseUrl+
'/downloadDefaultTopicsLibrary';

export const ROUTES = [
    // {
    //     name: 'topics',
    //     label: 'Topics',
    //     path: '/topics',
    //     icon:'pie-chart',
    //     component: Comments
    // },
    {
        name: 'comments',
        label: 'Comments',
        path: '/comments',
        icon:'bar-chart',
        component: Sentiment,
        default: true
    },
    {
        name: 'dashboard',
        label: 'Dashboard',
        path: '/dashboard',
        icon:'dashboard',
        component: Topics
    },
    {
        name: 'settings',
        label: 'Settings',
        path: '/settings',
        icon:'setting',
        component: Admin
    }
];
