import React from 'react';
import { Card } from 'antd';
import {Row, Col} from 'react-bootstrap';
import 
sentimentEmptyState 
    from '../../../components/SentimentEmptyState';

export function renderSentimentTable(data = [], fields = []){
    if (!data.length)
        return <sentimentEmptyState/>;
    return (
        <div>
            {data.map((table, index) =>
                <Card className='sentiment__selected' key={index}>
                    {fields.map((item, i) =>
                        <Row className='sentiment__selected--item' 
                            key={i}>
                            <Col xs={7} className='selected__title'
                                children={item.label} />
                            <Col xs={5} 
                                children={table[item.key]} />
                        </Row>)}
                </Card>)}
        </div>
    );
}