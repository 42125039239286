import React, { Component } from 'react';
import { Table, Spin } from 'antd';
import constants from '../constants';
import { addKey } from '../../../utils/addKey';

class TopicsTable extends Component {

  topicNameChange = (event) =>
      this.props.fetch_data({
          keyword:null,
          page:event.current,
          size:event.pageSize
      });

  render() {
      let {data,loading}=this.props.topicsApi;
      return (
          <Spin spinning={loading}>
              <Table
                  columns={constants.topicsColumns}
                  dataSource={addKey(data)}
                  onChange={this.topicNameChange}
                  pagination={{ total:this.props.topicsApi.data_count,
                      showSizeChanger:true }} />
          </Spin>
      );
  }
}

export default TopicsTable;