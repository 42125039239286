import React from 'react';
import { Alert } from 'react-bootstrap';
import { Spin } from 'antd';

const danger='danger';
const warning='warning';

const RenderComponent = (props) => {
    if (props.api.loading)
        return props.loader||<Spin/>;
    else if (props.api.error)
        return (
            <Alert variant={danger}>
                {props.error}
            </Alert>
        );
    else if (props.api.data)
        return props.children();
    else
        return (
            <Alert
                variant={warning}
                children={props.empty} />
        );
};

export default RenderComponent;