import * as actionTypes from '../../actionTypes';
import * as helper from './helper';
import intialState from './intialState';


const commentsReducer = (state = intialState, action) => {
    switch (action.type) {
    case actionTypes.FETCH_COMMENTS_START:
        return helper.fetchCommentsStart(state);
    case actionTypes.FETCH_COMMENTS_SUCCESS:
        return helper.fetchCommentsSuccess(state, action);
    case actionTypes.FETCH_COMMENTS_FAIL:
        return helper.fetchCommentsFail(state,action);
    default:
        return state;
    }
};

export default commentsReducer;
