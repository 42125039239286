import React from 'react';
import footerLogo from '../../Assets/images/logo_color.png';
import './index.scss';

const location='/';
const Logo=()=> {
    return (
        <div 
            test-name='cb-logo'
            className='logo'>
            <span 
                onClick={()=>window.location.href=location}> 
                <img 
                    src={footerLogo}
                    alt={''} />
            </span>
        </div>
    );
};

export default Logo;