import Sentiment from './Sentiment';
import { connect } from 'react-redux';
import { 
    fetchSentiment ,
    fetchBatchIds,
    fetchFilters,
    updateActiveFacet,
    updateFacetList,
    resetFacetList
} from '../../Redux/Actions/sentimentAction';


function mapStateToProps(state) {
    return {
        ...state.SentimentReducer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        fetchSentiment: payload => dispatch(fetchSentiment(payload)),
        fetchBatchIds:payload=>dispatch(fetchBatchIds(payload)),
        fetchFilters:()=>dispatch(fetchFilters()),
        updateActiveFacet:(facet)=>dispatch(updateActiveFacet(facet)),
        updateFacetList:(payload)=>dispatch(updateFacetList(payload)),
        resetFacetList:()=>dispatch(resetFacetList()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Sentiment);