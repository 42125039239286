import React from 'react';
import constants from '../constants';
import Search from 'antd/lib/input/Search';
class RenderFiltersAndSearch extends React.Component{

        
    render(){
        const {search} = constants.filtersFields;
        return (
            <div className='sentiment-search'>
                <Search
                    value={this.props.search}
                    className='search' placeholder={search} 
                    onChange={this.props.onChange}/>
            </div>
        );
    }
}

export default RenderFiltersAndSearch;