import React from 'react';
import ContentLoader from 'react-content-loader';

const MyLoader = () => (
    <ContentLoader 
        height={160}
        width={400}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb">
        <rect x="77" y="20" rx="1" ry="1" width="255" height="150" />
    </ContentLoader>
);

export default MyLoader;