import { 
    apiStartState,
    apiSuccessCase,
    apiFailureCase
} from '../../../utils/reduxOperations';

export function fetchTopicsStart(state) {
    return {
        ...state,
        topicsApi: apiStartState(state.topicsApi)
    };
};

export function fetchTopicsSuccess(state, action) {
    return {
        ...state,
        keyword:action.keyword,
        topicsApi: apiSuccessCase(state.topicsApi,action)
    };
}

export function fetchTopicsFail(state, action) {
    return {
        ...state,
        topicsApi: apiFailureCase(state.topicsApi,action)
    };
};

export function fetchKeywordStart(state) {
    return {
        ...state,
        keywordApi: apiStartState(state.keywordApi)
    };
};

export function fetchKeywordSuccess(state, action) {
    return {
        ...state,
        keywordApi: apiSuccessCase(state.keywordApi,action)
    };
}

export function fetchKeywordFail(state, action) {
    return {
        ...state,
        keywordApi: apiFailureCase(state.keywordApi,action)
    };
};


export function fetchTopicsRelationStart(state) {
    return {
        ...state,
        topicsRelationApi: {
            ...apiStartState(state.topicsRelationApi),
            data:null
        }
    };
};

export function fetchTopicsRelationSuccess(state, action) {
    return {
        ...state,
        topicsRelationApi:
         apiSuccessCase(state.topicsRelationApi,action)
    };
}

export function fetchTopicsRelationFail(state, action) {
    return {
        ...state,
        topicsRelationApi: 
        apiFailureCase(state.topicsRelationApi,action)
    };
};