import * as helper from './helper';
import * as actionTypes from '../../actionTypes';
import initialState from './intialState';

const topicsReducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.FETCH_TOPICS_START:
        return helper.fetchTopicsStart(state);
    case actionTypes.FETCH_TOPICS_SUCCESS:
        return helper.fetchTopicsSuccess(state, action);
    case actionTypes.FETCH_TOPICS_FAIL:
        return helper.fetchTopicsFail(state, action);
    case actionTypes.FETCH_KEYWORD_START:
        return helper.fetchKeywordStart(state);
    case actionTypes.FETCH_KEYWORD_SUCCESS:
        return helper.fetchKeywordSuccess(state, action);
    case actionTypes.FETCH_KEYWORD_FAIL:
        return helper.fetchKeywordFail(state, action);
    default:
        return fetchTopicRelation(state,action);
    }
};

const fetchTopicRelation=(state,action)=>{
    switch(action.type){
    case actionTypes.FETCH_TOPICS_RELATION_START:
        return helper.fetchTopicsRelationStart(state);
    case actionTypes.FETCH_TOPICS_RELATION_SUCCESS:
        return helper.fetchTopicsRelationSuccess(state, action);
    case actionTypes.FETCH_TOPICS_RELATION_FAIL:
        return helper.fetchTopicsRelationFail(state, action);
    default :return {...state};
    }
};
export default topicsReducer;