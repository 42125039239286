import React from 'react';
import { replaceTextRecurse } from '../../utils/replaceTextRecurse';
export default {
    warning:'',
    error:'error',
    emptyMessage:'No data found',
    emptySpace:' ',
    topicsColumns: [
        {
            title: 'Topic',
            dataIndex: 'text',
            key: 'text',
            render: (text) => <div dangerouslySetInnerHTML={
                { __html:
                 replaceTextRecurse(text, 
                     { split: '<em>', join: '</em>' }) }}/>
        }
    ],
    keywordColumns: [
        {
            title: 'Keyword',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Count',
            dataIndex: 'doc_count',
            key: 'doc_count',
        }
    ],
    defaultBubbleCount:7,
    topicFinderErrorMsg:'unable to fetch data',
    dropdownPlaceholder:'Batch Ids',
    relationsTitle:'Topics Relation',
    defaultState:{
        pageSize:10,
        batchId:undefined,
        pagination:{
            current:1,
            pageSize:10
        }
    },

};