import React from 'react';
import { Card } from 'react-bootstrap';
import RenderComponent from '../../../../components/RenderComponent';
import { List, Popconfirm, Icon } from 'antd';
import constants from '../../constants';
import Search from 'antd/lib/input/Search';
import TableLoader from './loader';

class RenderBatchIds extends React.Component{

    constructor(props){
        super(props);
        this.state={searchText:''};
        this.props.fetchData();
    }

    getListHeader=()=>{
        return(
            <div className='list-header'>
                <span children={constants.batchIdList.title}/>
                <Search 
                    onChange={(e)=>
                        this.setState({searchText:e.target.value})}
                    value={this.state.searchText} 
                    className='search'/>
            </div>
        );
    }

    getListSource=()=>{
        return this.props.batchIdsApi.data
            .filter(item=>
                item.includes(this.state.searchText));
    }

    renderBatchIdList=()=>{
        const {size} = constants.batchIdList;
        return(
            <List
                header={this.getListHeader()}
                dataSource={this.getListSource()}
                renderItem={item => this.renderListItem(item)}
                size={size} />
        );
    }

    deleteBatchId=(item)=>{
        this.props.deleteBatchId(item);
    }

    renderListItem=(item)=>{
        const {popup} = constants.batchIdList;
        return(
            <List.Item className='list-item'>
                <span children={item} />
                <Popconfirm
                    title={popup.title}
                    onConfirm={this.deleteBatchId.bind(null,item)}
                    okText={popup.okText}
                    cancelText={popup.cancelText} >
                    <Icon type={popup.iconType}/>
                </Popconfirm>
            </List.Item>
        );
    }

    render(){
        return (
            <Card className='list-batchids'>
                <RenderComponent
                    error={constants.batchIdList.errorMessage}
                    empty={constants.batchIdList.emptyMessage}
                    children={this.renderBatchIdList}
                    loader={<TableLoader/>}
                    api={this.props.batchIdsApi}/>
            </Card>
        );
    }
}
export default RenderBatchIds;