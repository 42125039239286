import * as d3 from 'd3';
import { findRangeFromNodes,
    findRangeFromEdge, 
    customTooltip
} from './helper';

export default (props) => {
    const { links, nodes, height, width } = getDatas(props);
    const simulation = getSimulation({ links, nodes, width, height });
    const svg = createSvg(width, height);
    const link = appendLinkToSvg({svg,links,edges:props.data.edges});
    const node = appendNodesToSvg({svg, nodes,simulation});
    const circle =
    appendCircleToNode({ node, nodes: props.data.nodes });
    const c2=svg.selectAll('.nodes')
        .append('circle')
        .attr('fill','none')
        .attr('stroke',(rec)=>rec.color)
        .attr('stroke-width',.4)
        .attr('r',(rec=>getRadiusRange(rec,props.data.nodes)+2));
    const text = appendTextToNode(node);
    return simulateGraph({ link, svg, circle, text,
        width,height, simulation,c2 });
};

function getDatas(props) {
    return {
        links: props.data.edges.map(d => Object.create(d)),
        nodes: props.data.nodes.map(d => Object.create(d)),
        height: props.height || 250,
        width: props.width || 500
    };
}

function getSimulation({ links, nodes, width, height }) {
    return d3
        .forceSimulation(nodes)
        .force('link',d3.forceLink(links).id(d=>d.node).distance(120))
        .force('charge', d3.forceManyBody())
        .force('center', d3.forceCenter(width / 2, height / 2));
}

function createSvg(width, height) {
    return d3
        .select('#bubble-chart')
        .append('svg')
        .attr('viewBox', [0, 0, width, height]);
}

function appendLinkToSvg({ svg, links, edges }) {
    const link = svg
        .append('g')
        .attr('stroke', '#b8bfc2')
        .attr('stroke-opacity', 1)
        .selectAll('line')
        .data(links)
        .join('line')
        .attr('cursor','pointer')
        .attr('stroke-width', (rec) => getLinkStroke(rec, edges));
    link.append('title')
        .text((rec) => rec.width);
    return link;
}

function appendNodesToSvg({svg, nodes,simulation}) {
    return svg
        .selectAll('circle')
        .data(nodes)
        .join('g')
        .attr('class','nodes')
        .on('mouseover', showTooltip)
        .on('mouseout', hideTooltip)
        .call(createDrag(simulation));
}

function appendCircleToNode({ node, simulation, nodes }) {
    const res=node
        .append('circle')
        .attr('class','node-circle')
        .attr('r', (rec) => getRadiusRange(rec, nodes))
        .attr('fill', (rec)=>rec.color)
        .attr('cursor', 'pointer');
    return res;
}

var div = d3.select('body').append('div')	
    .attr('class', 'tooltip')
    .style('opacity', 0);

function showTooltip(rec) {	
    div.transition()		
        .duration(100)
        .style('display','block')
        .style('opacity', .9);		
    div.html(customTooltip(rec))	
        .style('left', (d3.event.pageX+30) + 'px')		
        .style('top', (d3.event.pageY-30) + 'px');	
}

function hideTooltip(d) {		
    div.transition()		
        .duration(100)	
        .style('display','none')	
        .style('opacity', 0);	
}

function appendTextToNode(node) {
    return node.append('text')
        .text((rec) => rec.node
      + '(' + (
            Number.isInteger(rec.count)
                ? rec.count
                : rec.count.toFixed(2)
        )+ ')'
        )
        .style('cursor','pointer')
        .style('fill','#181819')
        .style('font-size',5)
        .attr('font-family', 'sans-serif');
}

function getRadiusRange(rec, nodes) {
    const { max, min } = findRangeFromNodes(nodes);
    return ((rec.count - min) / (max - min)) * (20 - 10) + 10;
}

function getLinkStroke(rec, edges) {
    const { max, min } = findRangeFromEdge(edges);
    return ((rec.width - min) / (max - min)) * (4 - 1) + 1;
}

function createDrag(simulation) {
    function dragstarted(d) {
        if (!d3.event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
    }
    function dragged(d) {
        d.fx = d3.event.x;
        d.fy = d3.event.y;
    }
    function dragended(d) {
        if (!d3.event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
    }
    return d3.drag()
        .on('start', dragstarted)
        .on('drag', dragged)
        .on('end', dragended);
}
function simulateGraph({ svg,width,height ,simulation, 
    circle, link, text,c2 }) {
    simulation.on('tick', () => {
        circle
            .attr('cx',d=>getCalculatedValue(width,d.x))
            .attr('cy', d=>getCalculatedValue(height,d.y));
        link
            .attr('x1', d =>getCalculatedValue(width, d.source.x))
            .attr('y1', d =>getCalculatedValue(height, d.source.y))
            .attr('x2', d =>getCalculatedValue(width, d.target.x))
            .attr('y2', d =>getCalculatedValue(height, d.target.y));
        c2
            .attr('cx', d=>getCalculatedValue(width, d.x))
            .attr('cy',d=>getCalculatedValue(height, d.y));
        text
            .attr('dx', d =>getCalculatedValue(width,d.x))
            .attr('dy', d =>getCalculatedValue(width,d.y+2));
    });
    return svg.node();
}

function getCalculatedValue(dimension,value){
    return Math.max(16, Math.min(dimension - 16, value));
}