const domain = 'https://culturebie';
const returnDomain = 'https://nlp.culturebie.com/';
const urlSuffix = '.auth.us-east-1.amazoncognito.com/logout';
const client_id = '?client_id=1ki0acdsspveoijk0etauf4mbj';
const logout_url = '&logout_uri=' + returnDomain;
const redirect_url = '&redirect_uri=' + returnDomain;

export default{
    title:'Topic Finder',
    logout_url:(domain+urlSuffix+client_id+logout_url+redirect_url),
    emptySpace:' ',
    logout:'logout',
    logo:'logo',
    active:'active',
    local:{
        firstName:'first_name',
        lastName:'last_name'
    },
    dropDown:{
        variant:'none',
        id:'profile-img'
    },
    space:' '
};