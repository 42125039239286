import React from 'react';
import {Component} from 'react';

class FormField extends Component {
    
    constructor(props) {
        super(props);
        this.makeClassName = this.makeClassName.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onEnterPressed = this.onEnterPressed.bind(this);
    }

    makeClassName() {
        let classList = ['form-control'];
        if (this.props.field.isValid === false)
            classList.push('has-error');
        return classList.join(' ');
    }

    onInputChange(event) {
        this.props.loginFormChange({
            index: this.props.index,
            event: event
        });
    }

    onEnterPressed(event) {
        if (event.keyCode === 13) this.props.onEnter(event);
    }

    render() {
        return (
            <div 
                className='input-wrapper'
                test-name='input-wrapper'>
                <input
                    test-name='input-field'
                    className={this.makeClassName()}
                    name={this.props.field.name}
                    type={this.props.field.type}
                    placeholder={this.props.field.placeholder}
                    value={this.props.field.value}
                    onChange={this.onInputChange}
                    onKeyUp={this.onEnterPressed}/>
                <span className='error-message'
                    test-name='error-message'
                    children={this.props.field.message}/>
            </div>
        );
    }
}

export default FormField;
