import React, { Component } from 'react';
import Keywords from './children/keywordsTable';
import TopicsTable from './children/topicsTable';
import { Card } from 'react-bootstrap';
import './index.scss';
import { RenderTopicsRelation } from './children/renderTopicsGraph';
import constants from './constants';

class Topics extends Component {
    constructor(props) {
        const {defaultState}=constants;
        super(props);
        this.state = { 
            pagination: { 
                current:defaultState.pagination.current,
                pageSize: defaultState.pagination.pageSize
            },
            batchId:defaultState.batchId,
            size:defaultState.pageSize
        };
        // this.fetchTopicData({});
        this.props.fetchTopicsRelation({});
        this.props.fetchBatchIds();
    }

    fetchTopicsRelation=({size,batchId})=>{
        this.props.fetchTopicsRelation({
            size:size||this.state.size,
            batchId:batchId||this.state.batchId
        });
    }

    onChangeRelationCount=(size)=>{
        this.setState({size});
        this.fetchTopicsRelation({size});
    }
    onChangeDropdown=(batchId)=>{
        this.setState({batchId});
        this.fetchTopicsRelation({batchId});
    }

    fetchTopicData = ({ keyword, page, size }) => {
        if (page && size)
            this.setState({
                pagination:{
                    current: page,
                    pageSize:size
                }
            });
        let { current, pageSize } = this.state.pagination;
        pageSize = size || pageSize;
        current = page || current;
        const reqPage = (current * pageSize) - pageSize;
        this.props.fetchTopics({
            keyword: keyword || this.props.keyword,
            size: pageSize,
            page: reqPage > 0 ? reqPage : 0
        });
    };

    renderKeyword = () =>
        <Card>
            <Card.Body>
                <TopicsTable
                    topicsApi={this.props.topicsApi}
                    fetch_data={this.fetchTopicData} />
            </Card.Body>
        </Card>

    renderTopics = () =>
        <Card>
            <Card.Body>
                <Keywords
                    keyword={this.props.keyword}
                    keywordApi={this.props.keywordApi}
                    fetchKeywords={this.props.fetchKeywords}
                    fetch_Topics={this.fetchTopicData} />
            </Card.Body>
        </Card>

    render() {
        return (
            <div className='topics'>
                <RenderTopicsRelation
                    onChangeDropdown={this.onChangeDropdown}
                    dropdownData={this.props.batchIds}
                    onChange={this.onChangeRelationCount}
                    apiData={this.props.topicsRelationApi}/>
                {/* <Card>
                    <Row>
                        <Col xs={5}
                            children={this.renderTopics()} />
                        <Col xs={7}
                            children={this.renderKeyword()} />
                    </Row>
                </Card> */}
            </div>
        );
    }
}

export default Topics;
