const colors=['#00C49F','#FFBB28','#FF8042',
    '#F10342','violet','#CCFF33','cyan','yellow','salmon','#90FF52',
    'olive','darkred','#CC3399','darkcyan'];

const getRandomHex=()=> {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 5; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color+'F';
};

export const getRandomColor=(index=999)=> {
    if(colors[index])
        return colors[index];
    return getRandomHex();
};