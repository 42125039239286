import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import {BrowserRouter,Route,Switch,Redirect} from 'react-router-dom';
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import RootReducer from './Redux/index';
import Login from './Routes/Login';
import MainFrame from './Routes/MainFrame';
import ProtectedRoute from './components/ProtectedRoute';

const reduxDevtoolExt = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = reduxDevtoolExt || compose;
const composeEnhancer = composeEnhancers(applyMiddleware(thunk));
const store = createStore(RootReducer, composeEnhancer);


const app = (
    <Provider store={store}>
        <BrowserRouter>
            <Switch>
                <Route 
                    path='/login' 
                    component={Login} />
                <ProtectedRoute 
                    path='/'
                    component={MainFrame} />
                <Redirect  
                    from='/' 
                    to='/login'/>
            </Switch>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
