import * as actionTypes from '../../actionTypes';
import { BATCH_IDS_URL } from '../../../config';
import Axios from 'axios';

export const fetchBatchIds = () => {
    return dispatch => {
        dispatch({ type: actionTypes.GET_BATCH_IDS_STARTED });
        Axios.get(BATCH_IDS_URL)
            .then(res => fetchBatchIdsSuccess(dispatch,res))
            .catch(err => fetchBatchIdsFailed(dispatch,err));
    };
};

function fetchBatchIdsSuccess(dispatch,res){
    return dispatch({
        type:actionTypes.GET_BATCH_IDS_SUCCESS,
        data:res.data.data
    });
}

function fetchBatchIdsFailed(dispatch,err){
    return dispatch({
        type:actionTypes.GET_BATCH_IDS_FAIL,
        error:err.message
    });
}