import * as actionTypes from '../../actionTypes';
import { GET_USER_PROFILE_URL } from '../../../config';
import { ApiBase } from '../../../utils/apiBase';

export const getUserProfile = () => {
    const apiBaseInstance = new ApiBase();
    return dispatch => {
        dispatch({
            type:actionTypes.GET_USER_PROFILE_STARTED,
        });
        apiBaseInstance.instance.post(GET_USER_PROFILE_URL, {})
            .then(res => {
                dispatch({
                    type:actionTypes.GET_USER_PROFILE_SUCCESS,
                    data:res.data
                });
            })
            .catch(err => {
                dispatch({
                    type:actionTypes.GET_USER_PROFILE_FAIL,err
                });
            });
    };
};
