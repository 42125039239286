export default{
    fileUploadFail:'Uploading failed',
    fileUpoladSuccess:'Successfully Uploaded',
    formDataHeader:{
        'Content-Type': 'multipart/form-data'
    },
    formProcessSuccess:'Processing Started',
    formProcessFail:'Failed',
    defaultFileName:'default_topics_library.csv',
    download:'download',
    downloadingFailed:'downloading failed',
    a:'a',
    commentsFileApi:'commentsFileApi',
    libraryFileApi:'libraryFileApi',
    file:'file'
};