import * as actionTypes from '../../actionTypes';
import * as helper from './helper';
import initialState from './intialState';

const AdminReducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.UPDATE_FIELDS:
        return helper.updateFields(state,action);
    case actionTypes.UPLOAD_FILE_START:
        return helper.uploadFileStart(state,action);
    case actionTypes.UPLOAD_FILE_SUCCESS:
        return helper.uploadFileSuccess(state, action);
    case actionTypes.UPLOAD_FILE_FAIL:
        return helper.uploadFileFail(state,action);
    default:
        return processFiles(state,action);
    }
};

function processFiles(state,action){
    switch (action.type) {
    case actionTypes.PROCESS_FILES_START:
        return helper.processFilesStart(state,action);
    case actionTypes.PROCESS_FILES_SUCCESS:
        return helper.processFilesSuccess(state, action);
    case actionTypes.PROCESS_FILES_FAIL:
        return helper.processFilesFail(state,action);
    case actionTypes.CLEAR_FILES_DATA:
        return helper.clearData(state,action);
    case actionTypes.DOWNLOAD_DEFAULT_TOPICS_START:
        return helper.downloadLibraryStart(state, action);
    case actionTypes.DOWNLOAD_DEFAULT_TOPICS_SUCCESS:
        return helper.downloadLibrarySuccess(state,action);
    case actionTypes.DOWNLOAD_DEFAULT_TOPICS_FAIL:
        return helper.downloadLibraryFail(state,action);
    default:
        return getBatchIds(state,action);
    }
}

function getBatchIds(state,action){
    switch (action.type) {
    case actionTypes.GET_BATCH_IDS_STARTED:
        return helper.getBatchIdsStart(state,action);
    case actionTypes.GET_BATCH_IDS_SUCCESS:
        return helper.getBatchIdsSuccess(state,action);
    case actionTypes.GET_BATCH_IDS_FAIL:
        return helper.getBatchIdsFail(state,action);
    default: return {...state};
    }
}
export default AdminReducer;