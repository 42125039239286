export function apiStartState(payload=[]){
    return{
        ...payload,
        loading:true,
        error:false
    };
}

export function apiInitialState(){
    return{
        loading: null,
        error: null,
        data: null,
        data_count:0
    };
}

export function apiSuccessCase(payload,action){
    return {
        ...payload,
        loading: false,
        error: false,
        data: action.data,
        data_count:action.count
    };
}
export function apiFailureCase(payload,action){
    return {
        ...payload,
        loading: false,
        error: action.error
    };
}