import * as actionTypes from '../../actionTypes';
import {Default} from './InitialState';
import {
    getUserProfileStarted,
    getUserProfileFailed,
    getUserProfileSuccess
} from './Helpers';

const getProfilePageReducer = (state = Default(), action) => {
    switch (action.type) {
    case actionTypes.GET_USER_PROFILE_STARTED:
        return getUserProfileStarted(state, action);
    case actionTypes.GET_USER_PROFILE_SUCCESS:
        return getUserProfileSuccess(state, action);
    default:
        return getProfileFailed(state, action);
    }
};
const getProfileFailed = (state, action) => {
    switch (action.type) {
    case actionTypes.GET_USER_PROFILE_FAIL:
        return getUserProfileFailed(state, action);
    default:
        return {...state};
    }
};

export default getProfilePageReducer;
