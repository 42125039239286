import { 
    apiStartState,
    apiSuccessCase,
    apiFailureCase 
}from '../../../utils/reduxOperations';

export function fetchCommentsStart(state) {
    return {
        ...state,
        commentsApi:apiStartState(state.commentsApi)
    };
};

export function fetchCommentsSuccess(state, action) {
    return {
        ...state,
        commentsApi: apiSuccessCase(state.commentsApi,action)
    };
}

export function fetchCommentsFail(state, action) {
    return {
        ...state,
        commentsApi: apiFailureCase(state.commentsApi,action)
    };
};