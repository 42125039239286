import React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import FormField from '../../components/FormField';
import { Checkbox } from 'antd';
import constants from './constants';
import Logo from '../../components/Logo';
import { LoaderButton } from '../../components/LoaderButton';
import {CheckInLocalStorage}from '../../utils/localStorageOperations';

class Login extends Component {
    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.LoginError = this.LoginError.bind(this);
        this.renderFormFields = this.renderFormFields.bind(this);
        if (this.isUserRoleFetched()) 
            this.props.history.push(constants.path.dashboard);
    }

    handleLogin(event) {
        event.preventDefault();
        if (this.props.isFormValid)
            this.props.loginAction(
                this.generateLoginRequestBody());
    }

    generateLoginRequestBody() {
        let requestBody = {};
        this.props.inputBoxConfiguration.forEach(item => {
            requestBody[item.name] = item.value;
        });
        return requestBody;
    }

    LoginError() {
        if (
            !this.props.loginApi.loading &&
            this.props.loginApi.error
        ) {
            return (
                <li>
                    <p className='error-message'
                        children={this.props.loginApi.error} />
                </li>
            );
        } else
            return null;
    }

    renderFormFields() {
        return this.props.inputBoxConfiguration.map(
            (field, index) => (
                <li
                    key={index}
                    className='login-block'>
                    <FormField
                        index={index}
                        field={field}
                        onEnter={this.handleLogin}
                        loginFormChange={
                            this.props.loginFormChange} />
                </li>)
        );
    }

    loginButton() {
        const { loginApi, getUserProfileApi } = this.props;
        const { btn } = constants;
        return (
            <li>
                <LoaderButton
                    loader={loginApi.loading
                        || getUserProfileApi.loading}
                    disabled={!this.props.isFormValid}
                    onClick={this.handleLogin}
                    classList={btn.classess}
                    loaderClass={btn.loaderClass}
                    text={btn.text} />
            </li>
        );
    }
    forgotPasswordButton() {
        return (
            <li className="forgot-password">
                <span>
                    <Link
                        to={constants.path.forgot}
                        children={constants.forgotPassword} />
                </span>
                <span>
                    <Checkbox
                        children={constants.rememberMe} />
                </span>
            </li>);
    }

    renderLoginInputForm() {
        return (
            <div
                className='login-warpper__content'>
                <Logo />
                <h3 children={constants.login} />
                <ul
                    className='login-warpper__content--list'>
                    {this.renderFormFields()}
                    {this.LoginError()}
                    {this.loginButton()}
                    <li>
                        <span>{constants.footerText}</span>
                    </li>
                </ul>
            </div>
        );
    }

    redirectToDashBoard = () => {
        this.props.history.push(constants.path.dashboard);
    }

    isUserFetching = () => {
        return this.props.getUserProfile.loading;
    }

    isUserSignedIn() {
        return (
            CheckInLocalStorage(constants.local.idToken) &&
            !CheckInLocalStorage(constants.local.userRole) &&
            this.props.getUserProfileApi.loading === null
        );
    }

    fetchUserProfile = () => {
        if (this.isUserSignedIn())
            this.props.getUserProfile();
    }

    isUserRoleFetched() {
        return (
            CheckInLocalStorage(constants.local.idToken) &&
            CheckInLocalStorage(constants.local.userRole)
        );
    }
    routeBySignedInUser() {
        if (this.isUserRoleFetched()) {
            this.props.history.push(constants.path.dashboard);
        }
    }

    render() {
        this.fetchUserProfile();
        this.routeBySignedInUser();
        return (
            <div
                className='login-warpper'>
                {this.renderLoginInputForm()}
            </div>
        );
    }
}

export default Login;