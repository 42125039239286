import React from 'react';
import { Collapse, Button } from 'react-bootstrap';
import './index.scss';
import constants from './constants';
class FacetsMain extends React.Component {
    constructor(props) {
        super(props);
        this.showFacets = this.showFacets.bind(this);
        this.facetClicked = this.facetClicked.bind(this);
        this.collapseGroup = this.collapseGroup.bind(this);
        this.clearButton = this.clearButton.bind(this);
        this.renderMainFacets = this.renderMainFacets.bind(this);
        this.renderFacetOptions = this.renderFacetOptions.bind(this);
    }
    showFacets(value) {
        this.props.updateActiveFacet(value);
    }
    facetClicked(value, parent) {
        this.props.onClickFacetItem({
            main: parent,
            value
        });
    }
    renderMainFacets() {
        return this.props.facetObject != null 
            ?(this.props.facetObject.map((facet, key) => 
                <li key={key}>
                    <Button
                        className={facet.selected ? 'selected' : ''}
                        variant={constants.buttonVariant}
                        onClick={this.showFacets
                            .bind(null,facet.value)}
                        aria-controls={constants.btnId + facet.main}
                        children={facet.label}
                        aria-expanded={facet.main 
                            === this.props.defaultFacetGroup
                        }/>
                    {this.collapseGroup(facet.main)}
                </li>
            ))
            :constants.emptyString;
    }

    collapseGroup(groupLabel) {
        return (
            <Collapse
                in={groupLabel === this.props
                    .defaultFacetGroup}>
                <ul
                    className='facets-submenu'
                    id={constants.btnId + groupLabel}>
                    {this.renderFacetOptions(groupLabel)}
                </ul>
            </Collapse>
        );
    }

    clearButton() {
        return (
            <span
                onClick={this.props.resetFacetList}
                className='close-btn'>
                {constants.buttonText}
            </span>
        );
    }

    getFacetSubItem=(item)=>(
        <div className='facet-subitem'>
            <div children={item.value} />
            <div children={item.count} />
        </div>
    )
    
    renderFacetOptions(newkey) {
        return this.props.facetObject.map(item => {
            if (item && newkey && item[newkey])
                return item[newkey].map((clickableItem, key) => (
                    <li
                        key={key}
                        onClick={(e)=>this.facetClicked(clickableItem
                            .value, newkey)}
                        className={
                            clickableItem.selected
                                ? 'sub-button selected'
                                : 'sub-button'}>
                        {this.getFacetSubItem(clickableItem)}
                        {!!clickableItem.selected&&
                        <div>&#10003;</div>}
                    </li>
                )); 
            return constants.emptyString;
        });
    }

    render() {
        return (
            <div className='facets-list'>
                <div className='facets-list__header'>
                    <h3 children={constants.title} />
                    {this.clearButton()}
                </div>
                <ul className='facets-list__item'
                    children={this.renderMainFacets()} />
            </div>
        );
    }
}
export default FacetsMain;
