import Topics from './Topics';
import { connect } from 'react-redux';
import {
    fetchTopics,
    fetchKeywords,
    fetchTopicsRelation
} from '../../Redux/Actions/topicsActions';
import { fetchBatchIds } from '../../Redux/Actions/sentimentAction';

const mapStateToProps = state => ({ 
    ...state.TopicsReducer,
    batchIds:state.SentimentReducer.batchIdsApi.data
});
const mapDispatchToProps = dispatch => ({
    fetchTopics: payload => dispatch(fetchTopics(payload)),
    fetchTopicsRelation: payload => 
        dispatch(fetchTopicsRelation(payload)),
    fetchKeywords: payload => dispatch(fetchKeywords(payload)),
    fetchBatchIds:()=>dispatch(fetchBatchIds())
});
export default connect(mapStateToProps, mapDispatchToProps)(Topics);