import Axios from 'axios';
import { DEFAULT_LIBRARY_URL } from '../../../config';
import * as actionTypes from '../../actionTypes';
import { notification } from 'antd';
import constants from './constants';

export function downloadDefaultTopics() {
    return dispatch => {
        dispatch({ type: actionTypes.DOWNLOAD_DEFAULT_TOPICS_START });
        Axios
            .get(DEFAULT_LIBRARY_URL)
            .then(response => downloadSuccess(dispatch, response))
            .catch(e =>downloadFailed(dispatch,e));
    };
}

function downloadSuccess(dispatch, response) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement(constants.a);
    link.href = url;
    link.setAttribute(constants.download, constants.defaultFileName);
    link.click();
    dispatch({
        type: actionTypes.DOWNLOAD_DEFAULT_TOPICS_SUCCESS
    });
}

function downloadFailed(dispatch, error) {
    notification.error({
        message:constants.downloadingFailed,
        description:error.response&&error.response.data
    });

    dispatch({
        type: actionTypes.DOWNLOAD_DEFAULT_TOPICS_FAIL
    });
}