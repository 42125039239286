import * as actionTypes from '../../actionTypes';
import {
    handleLoginFormChange,
    handleLoginProcessStarted,
    handleLoginProcessSuccess,
    handleLoginProcessFailed
} from './Helpers';

import { Default } from './InitialState';

const loginPageReducer = (state = Default(), action) => {
    switch (action.type) {
    case actionTypes.LOGIN_FORM_CHANGE:
        return handleLoginFormChange(state, action);
    case actionTypes.LOGIN_PROCESS_STARTED:
        return handleLoginProcessStarted(state, action);
    default: return loginSuccessFail(state, action);
    }
};

const loginSuccessFail = (state, action) => {
    switch (action.type) {
    case actionTypes.LOGIN_PROCESS_SUCCESS:
        return handleLoginProcessSuccess(state, action);
    case actionTypes.LOGIN_PROCESS_FAILED:
        return handleLoginProcessFailed(state, action);
    default:
        return { ...state };
    }
};

export default loginPageReducer;
