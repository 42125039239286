import React from 'react';
import { Select, Checkbox, Button, Popover, Empty, Icon } from 'antd';
const { Option } = Select;

const defaultWidth=200;

export function DropDown(props){
    if(props.single)
        return getDropDown(props);
    return(
        <Popover className='dropdown-popover' 
            placement='bottom'
            trigger='click'
            content={getContent(props)}>
            <Button className='dropdown'>
                {props.placeholder}
                <Icon type='down'/>
            </Button>
        </Popover>
    );
}

function getContent(props){
    if(!props.data.length)
        return <Empty/>;
    const content=(
        <Checkbox.Group
            value={props.defaultValue||[]} 
            onChange={(e)=>{props.onChange(e);}}
            className='dropdown-checkbox'>
            {props.data&&props.data.map(item=>
                <p><Checkbox
                    key={item} value={item}
                    children={item}/></p>)}
        </Checkbox.Group>
    );
    return content;
}

function getDropDown(props){
    return(
        <Select 
            onChange={props.onChange}
            className='dropdown'
            placeholder={props.placeholder}
            style={{
                width:props.width||defaultWidth
            }}>
            {(props.data||[]).map(item=>
                <Option
                    key={item.value||item}
                    value={item.value||item}
                    children={item.label||item}/>
            )}
        </Select>
    );
}