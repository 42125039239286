import {fetchFormConfigurations} from '../../inputFormConfigurations';
import { apiInitialState } from '../../../utils/reduxOperations';
export function Default() {
    return {
        isFormValid: false,
        loginApi: apiInitialState(),
        getUserProfileApi: apiInitialState(),
        inputBoxConfiguration: fetchFormConfigurations([
            'username',
            'password'
        ])
    };
}
