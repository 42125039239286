export const FETCH_COMMENTS_START = 'FETCH_COMMENTS_START';
export const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS';
export const FETCH_COMMENTS_FAIL = 'FETCH_COMMENTS_FAIL';

export const MAP_SENTIMENT_TO_FILTER = 'MAP_SENTIMENT_TO_FILTER';
export const FETCH_SENTIMENT_START = 'FETCH_SENTIMENT_START';
export const FETCH_SENTIMENT_SUCCESS = 'FETCH_SENTIMENT_SUCCESS';
export const FETCH_SENTIMENT_FAIL = 'FETCH_SENTIMENT_FAIL';

export const FETCH_TOPICS_START = 'FETCH_TOPICS_START';
export const FETCH_TOPICS_SUCCESS = 'FETCH_TOPICS_SUCCESS';
export const FETCH_TOPICS_FAIL = 'FETCH_TOPICS_FAIL';

export const FETCH_TOPICS_RELATION_START =
  'FETCH_TOPICS_RELATION_START';
export const FETCH_TOPICS_RELATION_SUCCESS =
  'FETCH_TOPICS_RELATION_SUCCESS';
export const FETCH_TOPICS_RELATION_FAIL =
  'FETCH_TOPICS_RELATION_FAIL';

export const FETCH_KEYWORD_START = 'FETCH_KEYWORD_START';
export const FETCH_KEYWORD_SUCCESS = 'FETCH_KEYWORD_SUCCESS';
export const FETCH_KEYWORD_FAIL = 'FETCH_KEYWORD_FAIL';

export const LOGIN_FORM_CHANGE='LOGIN_FORM_CHANGE';
export const LOGIN_PROCESS_STARTED='LOGIN_PROCESS_STARTED';
export const LOGIN_PROCESS_SUCCESS='LOGIN_PROCESS_SUCCESS';
export const LOGIN_PROCESS_FAILED='LOGIN_PROCESS_FAILED';

export const GET_USER_PROFILE_STARTED = 'GET_USER_PROFILE_STARTED';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL';

export const  UPLOAD_FILE_START= 'UPLOAD_FILE_START';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAIL = 'UPLOAD_FILE_FAIL';
export const UPDATE_FIELDS='UPDATE_FIELDS';

export const FETCH_BATCH_IDS_STARTED = 'FETCH_BATCH_IDS_STARTED';
export const FETCH_BATCH_IDS_SUCCESS = 'FETCH_BATCH_IDS_SUCCESS';
export const FETCH_BATCH_IDS_FAIL = 'FETCH_BATCH_IDS_FAIL';

export const FETCH_FILTERS_STARTED = 'FETCH_FILTERS_STARTED';
export const FETCH_FILTERS_SUCCESS = 'FETCH_FILTERS_SUCCESS';
export const FETCH_FILTERS_FAIL = 'FETCH_FILTERS_FAIL';
export const UPDATE_ACTIVE_FACET = 'UPDATE_ACTIVE_FACET';
export const UPDATE_FACET_LIST = 'UPDATE_FACET_LIST';
export const RESET_FACET_LIST = 'RESET_FACET_LIST';

export const PROCESS_FILES_START = 'PROCESS_FILES_START';
export const PROCESS_FILES_SUCCESS = 'PROCESS_FILES_SUCCESS';
export const PROCESS_FILES_FAIL = 'PROCESS_FILES_FAIL';
export const CLEAR_FILES_DATA = 'CLEAR_FILES_DATA';

export const DOWNLOAD_DEFAULT_TOPICS_SUCCESS=
'DOWNLOAD_DEFAULT_TOPICS_SUCCESS';
export const DOWNLOAD_DEFAULT_TOPICS_FAIL=
'DOWNLOAD_DEFAULT_TOPICS_FAIL';
export const DOWNLOAD_DEFAULT_TOPICS_START=
'DOWNLOAD_DEFAULT_TOPICS_START';

export const GET_BATCH_IDS_STARTED = 'GET_BATCH_IDS_STARTED';
export const GET_BATCH_IDS_SUCCESS = 'GET_BATCH_IDS_SUCCESS';
export const GET_BATCH_IDS_FAIL = 'GET_BATCH_IDS_FAIL';
