import axios from 'axios';
import {ClearLocalStorage,GetLocalStorage}
    from '../utils/localStorageOperations';

export class ApiBase {
    constructor(params) {
        this.instance = axios.create({
            baseURL: '',
            headers: this.handleHeaders(params)
        });
        this.instance.interceptors.response.use(
            response => response,
            error => {
                this.getErrorResponse(error);
                return Promise.reject(error);
            }
        );
    }

    getErrorResponse(error) {
        if (
            error &&
            error.response &&
            error.response.data &&
            error.response.status === 401 &&
            error.response.data.code === 'tokenExpired'
        ) {
            ClearLocalStorage();
            window.location.href = '/login';
        }
    }
    handleHeaders=(params)=> {
        let token = GetLocalStorage('accessToken');
        if (params) return params;
        else if (!token)
            return {
                'Content-Type': 'application/json'
            };
        else
            return {
                'Content-Type': 'application/json',
                'Authorization': token
            };
    }
}
