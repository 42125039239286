import React from 'react';
import { Route } from 'react-router-dom';
import { RenderIfSignIn } from '../../utils/checkSignInAndRender';

class ProtectedRoute extends React.Component {

    render() {
        return (
            <Route
                key={this.props.path}
                path={this.props.path}
                name={this.props.name}
                render={properties => {
                    properties.match=this.props.computedMatch;
                    return RenderIfSignIn(properties,
                        this.props.component);
                }}
            />
        );
    }
}
export default ProtectedRoute;
