import Admin from './Admin';
import { connect } from 'react-redux';
import { 
    updateFields,
    fileUpload,
    processFiles,
    clearFormData,
    fetchBatchIds, 
    deleteBatchId
} from '../../Redux/Actions/adminAction';
import { downloadDefaultTopics 
} from '../../Redux/Actions/adminAction/downloadDefaultTopics';


function mapStateToProps(state) {
    return {...state.AdminReducer};
}
function mapDispatchToProps(dispatch) {
    return {
        updateFields:(payload)=>dispatch(updateFields(payload)),
        fileUpload:payload=>dispatch(fileUpload(payload)),
        processFiles:payload=>dispatch(processFiles(payload)),
        clearFormData:()=>dispatch(clearFormData()),
        downloadDefaultTopics:()=>dispatch(downloadDefaultTopics()),
        fetchBatchIds:()=>dispatch(fetchBatchIds()),
        deleteBatchId:(id)=>dispatch(deleteBatchId(id)),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Admin);