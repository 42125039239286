export function handleLoginFormChange(state, payload) {
    let inputBoxConfiguration=
    Object.assign([],state.inputBoxConfiguration);
    inputBoxConfiguration.map((item,index)=>{
        if(index===payload.index)
            updateInputFieldItem(item,payload);    
    });

    return {
        ...state,
        inputBoxConfiguration,
        isFormValid:checkIsFormValid(inputBoxConfiguration)
    };
}

function updateInputFieldItem(field, payload) {
    field.value = fetchTarget(payload).value;
    field.isValid = validateField(field);
    return field;
}

export const fetchTarget = payload => {
    return (
        (payload && payload.event && payload.event.target) || ''
    );
};

export const validateField = inputConfiguration => {
    let isValid = false;
    if (inputConfiguration.value.length <= 0)
        inputConfiguration.message =
            inputConfiguration.rule.emptyMessage;
    else if (
        !inputConfiguration.rule.condition.test(
            inputConfiguration.value
        )
    )
        inputConfiguration.message =
            inputConfiguration.rule.errorMessage;
    else {
        inputConfiguration.message = '';
        isValid = true;
    }
    return isValid;
};

export function checkIsFormValid(inputBoxConfiguration) {
    let invalidFields = inputBoxConfiguration.filter(
        field => !field.isValid || !field.value.length
    );
    return invalidFields.length ? false : true;
}